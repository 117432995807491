import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import { Toast,NavBar,Tabbar, TabbarItem,List,Col, Row,Form, Field, CellGroup,Button,Picker ,Search } from 'vant';
// import Vant from 'vant'
import VueCookies from 'vue3-cookies';

import 'vant/lib/index.css'
const app = createApp(App)
app.use(store)
app.use(router)
// app.use(Vant)
// app.use(VueCookies)
app.use(VueCookies, {
    expireTimes: "30d",
    path: "/",
    domain: "",
    secure: true,
    sameSite: "None"
});

app.use(NavBar)
app.use(Tabbar)
app.use(TabbarItem)
app.use(axios)
app.use(Col)
app.use(Form)
app.use(Field)
app.use(CellGroup)
app.use(Button)
app.use(Picker)
app.use(Search)
app.use(Row)
app.use(List)
app.mount('#app')
